import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addBrandWidgetEntries,
	BRAND_WIDGET_FETCH_ENTRIES, setBrandWidgetErrors,
} from './brandWidgetActions';

/**
 * Fetch entry list
 */
function* fetchBrandWidgets(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	const params = afCoreRequest.mergeParams({
		page: 0,
		size: 5,
	}, action.params);

	let url = '/api/public/v1/widgets/brands';
	let contentKey = 'content';
	if (params.similar) {
		url = '/api/public/v1/brands/similar';
		contentKey = null;
	}


	if (params.sort == 'undefined,undefined') {
        delete params.sort;
    }
	
	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({ 
				url,
				params,
			} as IRequestObject);
		});

		yield put(addBrandWidgetEntries(
			action.queryKey,
			response.getContent(contentKey),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setBrandWidgetErrors(action.queryKey, e));
	}
}

export function* brandWidgetSaga() {
	yield takeEvery(BRAND_WIDGET_FETCH_ENTRIES, fetchBrandWidgets);
}
